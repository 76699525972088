const siteReady = () => {
  fixFooter()

  $(window).resize(() => fixFooter())

  if ($('.alert').length > 0) {
    hideUnwantedFlashMessages()
  }

  bootstrapFixes()
  setGrayLayoutBg()
}

;(window as any).closeAlertHeader = function () {
  $('.view-school').removeClass('alert-wrapper')
  return $('.alert-header').remove()
}

// Dynamically add padding to body on page load & resize to match the footer height
let fixFooter = function () {
  $('body').css('padding-bottom', ($('footer') as any).outerHeight())
}

// Misc Bootstrap fixes
let bootstrapFixes = () =>
  $('.dropdown-menu').on('click', (event) => {
    event.stopPropagation()
  })

let setGrayLayoutBg = function () {
  if (($('.gray-layout') as any).size() > 0) {
    const grayBg = $('.gray-layout').css('background-color')
    $('body').css('background-color', grayBg)
  }
}

// These unwanted flash messages appear from time to time on signup / login forms
let hideUnwantedFlashMessages = function () {
  $('.alert:contains(You have signed up successfully)').hide()
  $('.alert:contains(Signed in successfully)').hide()
  $('.alert:contains(You need to sign in or sign up before continuing.)').hide()
  $('.alert:contains(Please update your password)').hide()
  return $('.alert:contains(You are already signed in)').hide()
}

$(document).on('page:load ready', siteReady)

function siteGuard(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined
}
