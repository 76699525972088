;(window as any).getQueryString = () => {
  const url = new URL(window.location.toString())
  const searchParams = url.searchParams || new URLSearchParams(url.search)
  const query: Record<string, string> = {}
  const entries = searchParams.entries()
  let entry: Record<string, any> = {}
  do {
    entry = entries.next()
    if (entry.value !== undefined) {
      const [key, value] = entry.value
      query[key] = value
    }
  } while (!entry.done)
  return query
}
;(window as any).queryParamPresent = function (param) {
  if ((window as any).getQueryString()[param] !== undefined) {
    return _.isString((window as any).getQueryString()[param])
  }
  return false
}

const stripSecurityParam = function () {
  let newHref
  let newQuery
  let query
  if (
    (window as any).queryParamPresent('sa') &&
    location.pathname.indexOf('secure') === -1
  ) {
    ;(window as any).trackEvent('Login')
    query = (window as any).getQueryString()
    delete query.sa
    newQuery = `?${$.param(query)}`
    newHref =
      newQuery.length > 1 ? location.pathname + newQuery : location.pathname
    history.replaceState({}, 'foo', newHref)
  }

  if (
    (window as any).queryParamPresent('sb') &&
    location.pathname.indexOf('secure') === -1
  ) {
    query = (window as any).getQueryString()
    delete query.sb
    newQuery = `?${$.param(query)}`
    newHref =
      newQuery.length > 1 ? location.pathname + newQuery : location.pathname
    return history.replaceState({}, 'foo', newHref)
  }
}

const firefoxVersion = function (userAgent) {
  if (userAgent.indexOf('Firefox') !== -1) {
    const firefoxString = userAgent.substr(userAgent.indexOf('Firefox'))
    const splits = firefoxString.split('/')
    const version = splits[1]
    if (version) {
      return parseFloat(version)
    }
  }
}

const nextPathName = function (redirect, search, userAgent) {
  if (firefoxVersion(userAgent) && firefoxVersion(userAgent) < 52) {
    return `/${redirect}/${search}`
  }
  return `/${redirect}/`
}

$(document).on('ready page:load', () => {
  stripSecurityParam()
  if ((window as any).queryParamPresent('redirect')) {
    if (
      (window as any).currentUser().role === 'owner' ||
      (window as any).currentUser().role === 'admin' ||
      (window as any).currentUser().role === 'affiliate' ||
      (window as any).currentUser().role === 'author'
    ) {
      return (window.location.pathname = nextPathName(
        (window as any).getQueryString().redirect,
        location.search,
        navigator.userAgent
      ))
    }
  }
})
;(window as any).redirects = {
  nextPathName,
  firefoxVersion,
}
