;(window as any).onloadRecaptchaCallback = () => {
  const $ = (window as any).$

  $('.g-recaptcha-v3').each(() => {
    if ($(this).find('iframe').length) {
      return
    }

    return (window as any).grecaptcha.ready(() => {
      const sitekey = $('.g-recaptcha-v3').attr('data-sitekey-v3')
      const action = $('.g-recaptcha-v3').attr('data-action')

      return (window as any).grecaptcha
        .execute(sitekey, { action })
        .then((token) =>
          $('.g-recaptcha-v3').append(
            `<input type="hidden" name="g-recaptcha-response" value="${token}">`
          )
        )
    })
  })
}
