const setAhoyConfig = function () {
  const keys = (window as any).getFedoraKeys()
  if (!keys) {
    return
  }
  const site_env = keys.env
  const ahoy = (window as any).ahoy

  if (site_env === 'development' || site_env === 'staging') {
    ahoy.debug()
  }

  if (keys.trackAllAhoy) {
    ahoy.trackAll()
  } else {
    if (keys.trackChangesAhoy) {
      ahoy.trackChanges()
    }
    if (keys.trackClicksAhoy) {
      ahoy.trackClicks()
    }
    if (keys.trackSubmitsAhoy) {
      ahoy.trackSubmits()
    }
  }
  return ahoy.trackView()
}

$(document).on('ready page:load', setAhoyConfig)
