const postMessageSig = 'HELLOSIGN_MODAL'

const openFormModal = function () {
  const client = new (window as any).HelloSign({
    client_id: $('meta[id=fedora-data]').attr('data-hellosign-id'),
  })

  client.on((window as any).HelloSign.events.SIGN, () => {
    const msg = {
      from: postMessageSig,
      message: 'complete',
      payload: {
        signature_id: (window as any).eventData.signature_id,
      },
    }
    return parent.postMessage(msg, '*')
  })

  return client.open($('.hellosign-url').attr('value'), {
    allowCancel: true,
    skipDomainVerification:
      $('meta[id=fedora-keys]').attr('data-env') !== 'production',
  })
}

if (window.location.pathname === '/admin/complete_tax_form') {
  $(document).ready(openFormModal)
}

const msg = {
  from: postMessageSig,
  message: 'loaded',
}

parent.postMessage(msg, '*')
