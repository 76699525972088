import 'angular'
import 'angular-sanitize'
import 'angular-ui-router/release/angular-ui-router.min.js'
import 'bootstrap-sass/assets/javascripts/bootstrap.js'
import 'iframe-resizer/js/iframeResizer.contentWindow.min.js'
import 'iframe-resizer/js/iframeResizer.min.js'
import 'jquery-ujs/src/rails.js'
import 'jquery.turbolinks/vendor/assets/javascripts/jquery.turbolinks.js'
import 'legacyjs/config/ahoy'
import 'legacyjs/config/analytics'
import 'legacyjs/config/async_fontawesome'
import 'legacyjs/config/filepicker'
import 'legacyjs/config/flags'
import 'legacyjs/config/keys'
import 'legacyjs/config/recaptcha'
import 'legacyjs/config/redirects'
import 'legacyjs/config/segment'
import 'legacyjs/config/wistia_visitor'
import 'legacyjs/legacy-student/comments/comments'
import 'legacyjs/legacy-student/courses/course_page'
import 'legacyjs/legacy-student/courses/directory'
import 'legacyjs/legacy-student/courses/show'
import 'legacyjs/legacy-student/courses/upsell'
import 'legacyjs/legacy-student/lectures/show'
import 'legacyjs/legacy-student/site/site'
import 'legacyjs/legacy-student/tax_forms/hellosign'
import 'vendor/javascripts/ahoy'
import 'vendor/javascripts/confetti-js'
import 'vendor/javascripts/jquery.cookie'
import 'vendor/javascripts/jquery.hideseek'
import 'vendor/javascripts/jquery.scrollto.js'
import 'vendor/javascripts/modernizr.js'
import 'vendor/javascripts/nprogress-turbolinks'
import './components/tooltip/global'
