// get server side data passed through data attributes
;(window as any).currentUser = () =>
  (window as any).$('#fedora-data').data() || false
;(window as any).setFedoraKeys = function () {
  this.fedora_keys = (window as any).$('#fedora-keys').data()
  this.school_data = (window as any).currentUser()
  this.fedora_user = (window as any).currentUser()
  if (!_.isNumber(this.fedora_user.id)) {
    return (this.fedora_user.completedLectureIds = [])
  }
}
// Customers are relying on this awful code, see github issue #4545
;(window as any).currentCourse = () => {
  const path = location.pathname
  if (
    path.indexOf('/courses/') === 0 &&
    path.indexOf('/courses/enrolled') === -1
  ) {
    // 9 is length of /courses/
    const pathName: any = window.location.pathname
    ;(window as any).current_course_slug = pathName
      .slice(9)
      .split('/')[0](window as any).course_params = {
      friendly_url: (window as any).current_course_slug,
    }
    ;(window as any).current_course = (window as any).courseData(
      (window as any).course_params
    )
    return (window as any).current_course
  }
  return {}
}
;(window as any).currentLectureId = function () {
  if (window.location.pathname.indexOf('/lectures/') === -1) {
    return null
  }
  // tslint:disable-next-line:radix
  return parseInt(
    window.location.pathname.slice(
      window.location.pathname.indexOf('lectures') + 'lectures/'.length
    )
  )
}
;(window as any).$(document).on('ready page:load', () => {
  ;(window as any).setFedoraKeys()
  ;(window as any).$('.course-listing').click(function (event) {
    event.preventDefault()
    return (window as any).Turbolinks.visit(
      (window as any).$(this).data().courseUrl
    )
  })

  const createAccessLimitText = function (date) {
    const dateObj =
      date && (window as any).moment && (window as any).moment(date)
    if (!dateObj || !dateObj.isValid()) {
      return ''
    }
    return dateObj.format('DD MMM YYYY')
  }

  const setupAccessLimitToolTipText = function () {
    const tooltipEl = document.querySelector(
      '.course-access-limit-tool-tip-icon'
    )
    const tooltipText = keysGuard(
      tooltipEl != null ? (tooltipEl as HTMLElement).dataset : undefined,
      (x) => x.tooltipText
    )
    if (tooltipEl && tooltipText && (window as any).tooltipComponent) {
      return (window as any).tooltipComponent(
        tooltipText,
        tooltipEl,
        'course-access-limit-tool-tip'
      )
    }
  }

  setupAccessLimitToolTipText()

  _.forEach(_.toPairs((window as any).fedora_user.reportCard), (data_array) => {
    const courseId = data_array[0]
    const reportCard = data_array[1]
    const courseBox = (window as any).$(
      `.course-listing[data-course-id="${courseId}"]`
    )

    // add access limit date
    const accessLimitedAtText = createAccessLimitText(
      (reportCard as any).access_limited_at
    )
    if (accessLimitedAtText) {
      const accesslimitEl = courseBox.find('.course-access-limit')
      if (accesslimitEl != null) {
        accesslimitEl.removeClass('hidden')
      }
      keysGuard(
        accesslimitEl != null ? accesslimitEl.find('span') : undefined,
        (x) => x.text(accessLimitedAtText)
      )
    }

    const progressBar = courseBox.find('.progressbar')
    const percentageBox = courseBox.find('.percentage')
    const priceBox = courseBox.find('.course-price')
    const subtitle = courseBox.find('.course-listing-subtitle')
    if (subtitle != null) {
      subtitle.addClass('hidden')
    }
    if (subtitle != null) {
      subtitle.attr('aria-hidden', 'true')
    }
    keysGuard(priceBox != null ? priceBox.parent() : undefined, (x1) =>
      x1.addClass('hidden')
    )
    keysGuard(priceBox != null ? priceBox.parent() : undefined, (x2) =>
      x2.attr('aria-hidden', 'true')
    )
    const percentageBoxContainer = (window as any)
      .$(courseBox.find('.percentage'))
      .parent()
      .parent()
    const progressBarFill =
      courseBox != null ? courseBox.find('.progressbar-fill') : undefined
    const percentCompleteRounded =
      (reportCard as any).percent_complete > 100
        ? 100
        : (reportCard as any).percent_complete
    progressBarFill.attr('style', `min-width: ${percentCompleteRounded}%;`)
    // show the progress bar
    if (percentageBoxContainer != null) {
      percentageBoxContainer.removeClass('hidden')
    }
    keysGuard(
      percentageBoxContainer != null
        ? percentageBoxContainer.parent()
        : undefined,
      (x3) => x3.removeClass('hidden')
    )
    keysGuard(
      percentageBoxContainer != null
        ? percentageBoxContainer.parent()
        : undefined,
      (x4) => x4.attr('aria-hidden', 'false')
    )
    progressBar.parent().removeClass('hidden')
    progressBar.parent().attr('aria-hidden', 'false')
    const courseBoxParent = keysGuard(
      courseBox.find('.course-progress'),
      (x5) => x5.parent()
    )
    if (courseBoxParent != null) {
      courseBoxParent.removeClass('hidden')
    }
    if (courseBoxParent != null) {
      courseBoxParent.attr('aria-hidden', 'false')
    }
    // set Percent complete
    if (percentageBox != null) {
      percentageBox.text(`${percentCompleteRounded}%`)
    }
    if (progressBarFill != null) {
      progressBarFill.attr('aria-valuenow', `${percentCompleteRounded}%`)
    }
  })

  return true
})

function keysGuard(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined
}
