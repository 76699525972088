import { iris } from '@teachable/iris'
import { getItem } from 'src/util/cookies'

// initialize Iris
try {
  const irisUrlTag = document.getElementById('iris-url')
  const irisUrl = (irisUrlTag && irisUrlTag.getAttribute('data-iris-url')) || ''

  iris.init({
    source: 'fedora-client',
    url: irisUrl,
    noun: 'user',
    noun_id: '',
    selector: '',
  })
} catch {
  console.error('Iris is not supported')
}

$(document).on('ready page:load', () => {
  checkWistiaNotLoading()
  const storeVisitorKey = (key) => {
    if (key === null || (window as any).$.cookie('wistiaVisitorKey') === key) {
      return
    }

    $.ajax({
      url: '/api/v1/wistia_visitors',
      data: { visitor_key: (window as any).visitorKey },
      method: 'POST',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    }).always(() => (window as any).$.cookie('wistiaVisitorKey', key))
  }

  if (wistiaGuard((window as any).currentUser(), (x) => x.id)) {
    const visitorKey = (window as any).Wistia.visitorKey.value()
    if (visitorKey) {
      storeVisitorKey(visitorKey)
    }
  }
})

function wistiaGuard(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined
}

function checkWistiaNotLoading() {
  try {
    // Check if Wistia global variable is loading
    const _visitorKey = (window as any).Wistia.visitorKey.value()
  } catch (error) {
    const attachments = document.getElementsByClassName(
      'attachment-wistia-player'
    )
    const attachment_id = attachments[0].getAttribute('data-attachment-id')

    fetch('https://fast.wistia.com/assets/external/E-v1.js')
      .then((response) => response.json())
      .then((data) => {
        window.iris.emit('clicked_ui', {
          name: 'wistia_video',
          parent_object: `student`,
          attachment_id: attachment_id,
          session_id: getItem('ahoy_visitor'),
          response: data,
        })
      })
  }
}
