export const tooltip = (
  content: string,
  anchorEl: HTMLElement | null,
  tooltipClass?: string
) => {
  const DISTANCE_FROM_ANCHOR_BOTTOM = 10
  const MIN_DISTANCE_FROM_BROWSER_RIGHT_EDGE = 10

  const proto = {
    init() {
      this.anchorEl = anchorEl

      // if the element already has a tooltip, don't add a new one
      if (!this.anchorEl || this.anchorEl.tooltip) {
        return
      }

      this.anchorEl.tooltip = this

      this.createTooltipEl = this.createTooltipEl.bind(this)
      this.positionTooltip = this.positionTooltip.bind(this)
      this.show = this.show.bind(this)
      this.hide = this.hide.bind(this)
      this.destroy = this.destroy.bind(this)

      this.anchorEl.addEventListener('mouseenter', this.show)
      this.anchorEl.addEventListener('mouseleave', this.hide)
    },

    createTooltipEl() {
      const addClass = tooltipClass || ''
      this.tooltipEl = document.createElement('div')
      this.tooltipEl.className = `lecture-tooltip ${addClass}`

      const arrowEl = document.createElement('div')
      arrowEl.className = 'tooltip-content-arrow'
      this.tooltipEl.appendChild(arrowEl)

      const contentEl = document.createElement('div')
      contentEl.className = 'tooltip-content'
      this.tooltipEl.appendChild(contentEl)
      contentEl.innerText = content

      document.body.appendChild(this.tooltipEl)
    },

    positionTooltip() {
      const anchorRect = this.anchorEl.getBoundingClientRect()
      const tooltipRect = this.tooltipEl.getBoundingClientRect()
      let leftPos =
        anchorRect.left + anchorRect.width / 2 - tooltipRect.width / 2
      const topPos =
        anchorRect.top + anchorRect.height + DISTANCE_FROM_ANCHOR_BOTTOM

      if (leftPos + tooltipRect.width > window.innerWidth) {
        leftPos =
          window.innerWidth -
          MIN_DISTANCE_FROM_BROWSER_RIGHT_EDGE -
          tooltipRect.width
      }

      this.tooltipEl.style.left = `${leftPos}px`
      this.tooltipEl.style.top = `${topPos}px`
    },

    show() {
      this.createTooltipEl()
      this.positionTooltip()
    },

    hide() {
      if (this.tooltipEl) {
        document.body.removeChild(this.tooltipEl)
        this.tooltipEl = null
      }
    },

    destroy() {
      this.hide()

      if (this.anchorEl) {
        this.anchorEl.tooltip = null
        this.anchorEl.removeEventListener('mouseenter', this.show)
        this.anchorEl.removeEventListener('mouseleave', this.hide)
        this.anchorEl = null
      }
    },
  }

  const inst = Object.create(proto)
  inst.init()

  return inst
}
