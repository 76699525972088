const onPage = (selector) => selector.length > 0

const selectors = {
  pagination: '.pagination .last',
  searchInput: '#search-courses',
}

const paginated = () => onPage(selectors.pagination)

const directoryReady = () => {
  const queryParams = (window as any).getQueryString

  // don't run unless search box is on page
  if (!onPage(selectors.searchInput)) {
    return
  }
  const searchBox = $(selectors.searchInput)

  // grab query and page param
  const urlSearchQuery = queryParams().query

  // if there's a query param in query string, put it in search box
  if (urlSearchQuery) {
    searchBox.val(urlSearchQuery)
  }
  // if there's no pagination or search query, use inline search is
  if ($('.pagination .last').length === 0) {
    return ($('#search-courses') as any).hideseek()
  }
}

$(document).ready(directoryReady)
$(document).on('page:change', directoryReady)
