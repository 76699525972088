const courseShowready = () => {
  ;(window as any).wistiaPlayers = loadPlayers()
  // ENROLLED COURSE PAGE
  if (courseShowGuard((window as any).currentUser(), (x) => x.id)) {
    ;(window as any)
      .currentUser()
      .completedLectureIds.forEach((lectureId) =>
        $(`.section-item[data-lecture-id="${lectureId}"]`)
          .addClass('completed')
          .removeClass('incomplete')
          .removeClass('next-lecture')
      )
  }
  courseShowSetLabelsForCompleted()
  // Mark user's next incomplete lecture in curriculum list
  if ($('.course-mainbar .incomplete').length > 0) {
    const incomplete_lecture = $(
      '.course-section:not(.course-section-locked) .incomplete'
    ).first()
    incomplete_lecture.addClass('next-lecture')
    courseShowSetLabelForCurrent()
    // Start Next Lecture section
    $('.course-mainbar .next-lecture-name').append(
      incomplete_lecture.find('.lecture-name').html()
    )
    $('.course-mainbar .start-next-lecture').attr(
      'href',
      incomplete_lecture.find('a').attr('href')
    )
  } else {
    $('.course-mainbar .start-next-lecture').addClass('disabled') // Disable start next lecture button
  }

  $('.product-enroll').click(() => $('#enroll-button').click())
}

const setVideoHeight = function (player) {
  if ($(player.container.parentElement).hasClass('wistia_responsive_wrapper')) {
    const paddingWrapper = player.container.parentElement.parentElement

    // vertical
    paddingWrapper.style.paddingBottom = '56.25%'
    return (paddingWrapper.style.height = '0px')
  }

  return undefined
}

let loadPlayers = function () {
  let e
  const players: any[] = []
  const playerElements: any[] = []
  for (e of Array.from($('.show-course-wistia-player'))) {
    playerElements.push(e)
  }
  for (e of Array.from($('.video-asset-wistia-player'))) {
    playerElements.push(e)
  }
  for (const element of Array.from(playerElements)) {
    const brandPlayerColor = $('meta[name="brand_video_player_color"]').attr(
      'content'
    )
    const playerColor = $(element).data('player-color')
      ? $(element).data('player-color')
      : brandPlayerColor
    const stillUrl =
      $(element).data('thumbnail') || $(element).data('still-url')
    const player = (window as any).Wistia.embed($(element).data().wistiaId, {
      container: $(element).attr('id'),
      playerPreference: 'html5',
      stillSnap: false,
      videoFoam: true,
      playerColor,
      stillUrl,
      googleAnalytics: false,
    })
    const promoVideo = $('#watchpromo')
    promoVideo.click(() => {
      if (
        (window as any).Modernizr.videoautoplay &&
        (promoVideo as any).attr('id')(!'watchpromo')
      ) {
        return player.play()
      }
    })

    setVideoHeight(player)

    players.push(player)
  }
  return players
}

$(document).on('ready page:load', courseShowready)

const incomplete_lecture = $('.course-mainbar .incomplete')
$('.course-mainbar .next-lecture-name').html(
  $('.course-mainbar .incomplete').first().find('.lecture-name').html()
)
$('.course-mainbar .start-next-lecture').attr(
  'href',
  incomplete_lecture.find('a').attr('href')
)

let courseShowSetLabelsForCompleted = function () {
  $('.section-item .status-container').removeAttr('aria-label')
  return $('.section-item.completed .status-container').attr(
    'aria-label',
    'Completed item'
  )
}

let courseShowSetLabelForCurrent = () =>
  $('.section-item.next-lecture .status-container').attr(
    'aria-label',
    'Current item'
  )

function courseShowGuard(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined
}
