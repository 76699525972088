/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { escape, unescape } from 'lodash'

/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-use-before-define */
const FILEPICKER_CONFIG = {
  services: ['COMPUTER', 'FACEBOOK', 'INSTAGRAM', 'GOOGLE_DRIVE', 'DROPBOX'],
  mimetypes: ['image/*'],
}

// eslint-disable-next-line max-len
const URL_REGEX = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\w]*))?)/g
const ANCHOR_TAG_REGEX = /<a[\s]+[^>]*?href[\s]?=[\s"']*(.*?)["']*.*?>([^<]+|.*?)?<\/a>/g

let currentUserData: any = {}
let schoolData: any = {}
let commentSettings: any = {}
let commentData: any = {
  loadedCount: 0,
  totalCount: 0,
  nextPage: null,
  currentPage: null,
  commentIds: [],
  ancestorIds: [],
}

const base64Icons = {
  // coffeelint: disable=max_line_length
  gravatarUrl:
    // eslint-disable-next-line max-len
    'data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD//gA7Q1JFQVRPUjogZ2QtanBlZyB2MS4wICh1c2luZyBJSkcgSlBFRyB2NjIpLCBxdWFsaXR5ID0gOTAK/9sAQwADAgIDAgIDAwMDBAMDBAUIBQUEBAUKBwcGCAwKDAwLCgsLDQ4SEA0OEQ4LCxAWEBETFBUVFQwPFxgWFBgSFBUU/9sAQwEDBAQFBAUJBQUJFA0LDRQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQU/8AAEQgARgBGAwEiAAIRAQMRAf/EAB8AAAEFAQEBAQEBAAAAAAAAAAABAgMEBQYHCAkKC//EALUQAAIBAwMCBAMFBQQEAAABfQECAwAEEQUSITFBBhNRYQcicRQygZGhCCNCscEVUtHwJDNicoIJChYXGBkaJSYnKCkqNDU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2drh4uPk5ebn6Onq8fLz9PX29/j5+v/EAB8BAAMBAQEBAQEBAQEAAAAAAAABAgMEBQYHCAkKC//EALURAAIBAgQEAwQHBQQEAAECdwABAgMRBAUhMQYSQVEHYXETIjKBCBRCkaGxwQkjM1LwFWJy0QoWJDThJfEXGBkaJicoKSo1Njc4OTpDREVGR0hJSlNUVVZXWFlaY2RlZmdoaWpzdHV2d3h5eoKDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uLj5OXm5+jp6vLz9PX29/j5+v/aAAwDAQACEQMRAD8A+t8Ck4ozRmgBeKOKM1taF4P1XxEN9pbHyc486Q7U/Pv+GaAMXg0YFdxJ8INZSPcs9m7f3A7A/qtcrq+h3+hXHk31s8DnoTyrfQjg0AUeKOKTNGaADiijNFAC4owKKKAOl8BeF18Ta0EmB+xwDzJsfxei/j/IGvc4oY4IkjiRY40G1UUYAHoBXnnwaCCw1JhjzDKgP0wcfzNei7vagBcVR1nRbXXtPks7uMPG44PdD2I9DV7PtSbvagD5x1jS5NF1S5spuZIXK5Hcdj+Iwap4rsfiuqDxa5XG5oULfXp/LFcdQAYFFFFABiiiigDr/hp4lj0DWmiuXCWt2AjOeisPuk+3JH417Z1rwHwx4OvvFMxFuoitlOHuHHyr7D1Pt/KvatA0Y6Fpsdp9rmuwnR5iOPYeg9uaANTFQXt5BptpLc3MgihjXczN2FS1zHjXwfL4qt1Ed/JA0fKwtzEx9SBzn35+lAHj/iLWH1/Wru+YFRK/yqf4VHCj8gKzauato93od69reQmGVeeejD1B7iqVAC4ooooAMVseFfDsvibWYrNCUj+/LIP4UHU/Xt+NY9ex/CXR1s9Ae+Zf3t25wf8AYU4A/PcaAOwsNPg0yzitbaMRQRDaqj/PWrGKWigBMUmKdRQBheLfC0HijTGgcBLhAWhm7o3+B714Jc2slncSwTIY5YmKOp6gg819L968g+LujrZ61BfRrhbtPmx/fXAJ/Ij8qAODxRRRQAYr6F8KwLb+GtLRegtoyfqVBP6miigDWxSEUUUAAGRQBRRQAYrhPjBbq/h21l/iS5AB9irZ/kKKKAPIKKKKAP/Z',
}

const ready = function () {
  if (location.pathname.indexOf('/blog/') === 0) {
    // these functions need to be called externally when viewing lectures (see show.coffee)
    // we call them locally when viewing blogs
    ;(window as any).resetCommentData()
    ;(window as any).setCommentData()
    ;(window as any).loadCommentsPage(1)
    ;(window as any).setupCommentHandlers()

    // Lecture pages have js in the liquid template that automatically does this but blog pages do not
    if ($('#disqus_data').data()) {
      setTimeout(() => {
        ;(window as any).updateDisqus()
        // tslint:disable-next-line:no-magic-numbers
      }, 1000)
    }
  }
}

;(window as any).setupCommentHandlers = function () {
  $('div.attachment-block-wrapper').unbind('click')
  $('div.attachment-block-wrapper').on('click', (event) => {
    const element = $(event.target).data().commentHandler
      ? event.target
      : event.target.parentNode

    const elCommentHandler = $(element as any).data().commentHandler
    if (
      elCommentHandler &&
      (elCommentHandler !== 'create_comment' ||
        elCommentHandler !== 'create_reply')
    ) {
      const handler = commentHandler(
        element,
        $(element as any).data().commentId
      )
      if (handler !== null) {
        event.preventDefault()
        handler.action()
      }
    }
  })
  $('.attachment-block-wrapper form').unbind('submit')
  $('.attachment-block-wrapper form').on('submit', (event) => {
    const element = event.target
    if ($(element).data().commentHandler) {
      const handler = commentHandler(element, $(element).data().commentId)
      if (handler !== null) {
        event.preventDefault()
        handler.action()
      }
    }
  })
}

// Returns a hash containing a function to call if the element
// has a handler type data attribute matching one of these types
const commentHandler = function (element, commentId) {
  const handlers = {
    create_comment: {
      action() {
        return createComment()
      },
    },
    create_reply: {
      action() {
        return createCommentReply($(element).data().parentId)
      },
    },
    update_comment: {
      action() {
        return updateComment(commentId)
      },
    },
    add_reply: {
      action() {
        return $(`#comment_${commentId}_reply_container`)
          .removeClass('hidden')
          .find('textarea')
          .focus()
      },
    },
    edit_comment: {
      action() {
        return renderEditCommentForm(commentId)
      },
    },
    approve_comment: {
      action() {
        return approveComment(commentId)
      },
    },
    delete_comment: {
      action() {
        return deleteComment(commentId)
      },
    },
    remove_image: {
      action() {
        return removeCommentAttachment(element)
      },
    },
    load_more: {
      action() {
        if (commentData.nextPage) {
          ;(window as any).loadCommentsPage(commentData.nextPage)
        }
      },
    },
    add_image: {
      action() {
        if (
          commentSettings != null
            ? (commentSettings as any).attachmentsEnabled
            : undefined
        ) {
          const imagesContainer = $((element as any).parentNode).find(
            '.attachments'
          )[0]
          ;(window as any).filepicker.pick(FILEPICKER_CONFIG, (response) => {
            const img = imageElement(response)
            const ulElement = imagesContainer.innerHTML
            // eslint-disable-next-line no-return-assign
            return (imagesContainer.innerHTML = ulElement + img)
          })
        }
      },
    },
  }
  return handlers[$(element).data().commentHandler]
}

;(window as any).resetCommentData = function () {
  currentUserData = {}
  schoolData = {}
  commentSettings = {}
  commentData = {
    loadedCount: 0,
    totalCount: 0,
    nextPage: null,
    currentPage: null,
    commentIds: [],
    ancestorIds: [],
  }
}
;(window as any).setCommentData = function () {
  if ($('#comments_settings').data()) {
    commentSettings = $('#comments_settings').data()
    if ($('#fedora-data').data()) {
      schoolData = $('#fedora-data').data()
    }
    currentUserData.id = schoolData.id
    currentUserData.name = schoolData.name
    currentUserData.role = schoolData.role
    currentUserData.gravatar_url = schoolData.gravatarUrl
    currentUserData.moderator = false
  }

  // Enable attachments if applicable
  if ((commentSettings as any).attachmentsEnabled) {
    ;(window as any).filepicker.setKey(commentSettings.filepickerKey)
    $('.new.attachments-editor').removeClass('hidden')
  }
}

// Gets a page of comments
;(window as any).loadCommentsPage = function (page) {
  commentSettings = {}
  if ($('#comments_settings').data()) {
    commentSettings = $('#comments_settings').data()
  }
  if (commentSettings.threadEndpoint && commentData.currentPage !== page) {
    const pageParam = `&page=${page}`
    let rootParam = ''
    // TODO - investigate wtf this statement is doing
    if ($('a#comments_pagination').data().commentRootId) {
      rootParam = `&root_comment_id=${
        $('a#comments_pagination').data().commentRootId
      }`
      $('#lecture_previous_button').addClass('hidden')
      $('#lecture_complete_button').addClass('hidden')
    }

    if (window.school_data) {
      const endpoint = `${commentSettings.threadEndpoint}${pageParam}${rootParam}`
      $.ajax({
        url: endpoint,
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        success(res) {
          if (
            commentsGuard(
              res != null ? res.comments : undefined,
              (x) => x.length
            )
          ) {
            commentData.loadedCount += res.comments.length

            // only need to set this meta.total once
            if (!commentData.totalCount) {
              commentData.totalCount = res.meta.total
            }

            commentData.currentPage = page

            // increment nextPage if we still have comments to load
            commentData.nextPage =
              commentData.loadedCount < commentData.totalCount ? page + 1 : null
            if (currentUserData.id) {
              currentUserData.moderator = commentsGuard(
                res != null ? res.meta : undefined,
                (x1) => x1.moderator
              )
            }
          }
          $('a#comments_pagination').data().moderator =
            currentUserData.moderator
          if (commentData.nextPage) {
            $('a#comments_pagination').removeClass('hidden')
          } else {
            $('a#comments_pagination').addClass('hidden')
          }
          // eslint-disable-next-line consistent-return
          return res.comments.forEach((comment) => {
            // keep track of comments with ancestors to better-handle UI on deletion
            if (comment.ancestry) {
              // ancestory id is stored as a string, parse it to int
              // tslint:disable-next-line:radix
              commentData.ancestorIds.push(parseInt(comment.ancestry))
            }
            if (commentData.commentIds.indexOf(comment.id) === -1) {
              renderCommentItem(comment)
              return commentData.commentIds.push(comment.id)
            }
          })
        },
      })
    }
  }
}

const addHttpToAnchorTags = (str) => {
  const matches = str.match(ANCHOR_TAG_REGEX)

  if (matches) {
    return matches.reduce((acc, cur) => {
      const accCopy = acc

      if (cur.indexOf('="http') === -1 && cur.indexOf("='http") === -1) {
        const newCur = cur.split('href="').join('href="http://')
        return accCopy.split(cur).join(newCur)
      }

      return accCopy
    }, str)
  }

  return str
}

const makeUrlLinks = (str) => {
  const hyperlinks = str.match(ANCHOR_TAG_REGEX)
  const matches = str.match(URL_REGEX)

  if (matches && !hyperlinks) {
    const newString = matches.reduce((acc, cur) => {
      const accCopy = acc
      // tslint:disable-next-line:no-magic-numbers
      const hrefCheckStart = str.indexOf(cur) - 2
      const hrefCheckEnd = 2
      const preceding = str.substr(hrefCheckStart, hrefCheckEnd)

      if (preceding === '="' || preceding === "='") {
        return accCopy
      }

      return accCopy
        .split(cur)
        .join(
          `<a target="_blank" rel="noopener noreferrer" href="${cur}">${cur}</a>`
        )
    }, str)

    return addHttpToAnchorTags(newString)
  }

  return addHttpToAnchorTags(str)
}

// Requests

const deleteComment = function (commentId) {
  $.ajax({
    url: `/api/v1/comments/${commentId}`,
    type: 'DELETE',
    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    success() {
      deleteAnimation(commentId)
      updateCommentCount(-1)
    },
  })
}

const approveComment = function (commentId) {
  $.ajax({
    url: `/api/v1/comments/${commentId}`,
    type: 'PUT',
    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    data: {
      workflow_event: 'approve',
    },
    success() {
      approveAnimation(commentId)
    },
  })
}

const updateComment = function (commentId) {
  const commentEditForm = $(`#comment_${commentId}_edit_form`)
  const commentEditFormBody = commentEditForm.find('textarea').val()
  const attachments = getFormCommentAttachments(
    `#comment_${commentId}_edit_form`
  )
  if (commentEditFormBody.replace(' ', '') !== '') {
    commentEditForm.find('#text_error_alert').hide()

    $.ajax({
      url: `/api/v1/comments/${commentId}`,
      type: 'PUT',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      data: {
        body: commentEditFormBody,
        attachments_attributes: attachments,
      },
      success(response) {
        renderUpdatedCommentSuccess(response, commentId)
        resetCommentForm(`#comment_${commentId}_edit_form`)
        $(`#comment_${commentId}_edit_form`).addClass('hidden')
        $(`#comment_${commentId}_edit_form`).find(
          '.attachments'
        )[0].innerHTML = commentAttachmentElements(response)
      },
      error() {
        // tslint:disable-next-line:no-console
        console.log('Error updating comment')
      },
    })
  } else {
    commentEditForm.find('#text_error_alert').show()
  }
}

const createComment = function () {
  const newCommentForm = $('#new_comment_form')
  newCommentForm.find('input[type=submit]').prop('disabled', true)
  const formData: any = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const input of Array.from(newCommentForm.serializeArray())) {
    formData[(input as any).name.match(/\[(.*?)\]/)[1]] = input.value
  }
  formData.attachments_attributes = getFormCommentAttachments(
    '#new_comment_form'
  )

  const commentFormData = { comments: formData }

  if (formData.body.replace(' ', '') !== '') {
    newCommentForm.find('#text_error_alert').hide()
    $.ajax({
      url: '/api/v1/comments/',
      type: 'POST',
      data: commentFormData,
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      success(response) {
        renderNewCommentSuccess(response, false)
        resetCommentForm('#new_comment_form')
        if (response.workflow_state === 'awaiting_review') {
          newCommentForm.find('#review_msg').show()
        } else {
          newCommentForm.find('#posted_msg').show()
        }
        commentData.commentIds.push(response.id)
        updateCommentCount(1)
        newCommentForm.find('input[type=submit]').prop('disabled', false)
      },
      error() {
        // tslint:disable-next-line:no-console
        console.log('Error creating new comment')
        $('.comments__block-box__alert--failed').show()
        newCommentForm.find('input[type=submit]').prop('disabled', false)
      },
    })
  } else {
    newCommentForm.find('#text_error_alert').show()
    newCommentForm.find('input[type=submit]').prop('disabled', false)
  }
}

const createCommentReply = function (commentId) {
  const formId = `#comment_${commentId}_reply_form`
  const replyForm = $(formId)
  replyForm.find('input[type=submit]').prop('disabled', true)
  const formData: any = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const input of Array.from(replyForm.serializeArray())) {
    formData[(input as any).name.match(/\[(.*?)\]/)[1]] = input.value
  }
  formData.attachments_attributes = getFormCommentAttachments(formId)
  const commentFormData = { comments: formData }
  if (formData.body.replace(' ', '') !== '') {
    replyForm.find('#text_error_alert').hide()
    $.ajax({
      url: '/api/v1/comments/',
      type: 'POST',
      data: commentFormData,
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      success(response) {
        renderNewCommentSuccess(response, commentId)
        resetCommentForm(formId)
        commentData.commentIds.push(response.id)
        // keep track of ancestor (parent) - need for removal of child comments
        commentData.ancestorIds.push(commentId)
        updateCommentCount(1)
      },
      error() {
        // tslint:disable-next-line:no-console
        console.log('Error creating comment reply')
      },
    })
  } else {
    replyForm.find('#text_error_alert').show()
    $(replyForm.find('input[type=submit]')[0]).prop('disabled', false)
  }
}

const removeCommentAttachment = function (element) {
  const item = element.parentNode
  const img = $(element).find('img').first()
  const container: any = $(item.parentNode)
  if (container.data().editableAttachments) {
    let commentId
    if (img.data('attachment-id') && img.data('comment-id')) {
      commentId = img.data('comment-id')
      const attachmentId = img.data('attachment-id')
      $.ajax({
        url: `/api/v1/comments/${commentId}/attachments/${attachmentId}`,
        type: 'DELETE',
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        success() {
          $(item).remove()
        },
        error() {
          // tslint:disable-next-line:no-console
          return console.log('Error deleting attachment')
        },
      })
    } else {
      $(item).remove()
    }
    if (
      !$.trim(container.innerHTML) ||
      $.trim(container.innerHTML).length === 0
    ) {
      $(`#comment_${commentId}`)
        .find('.comment-content')
        .find('ul.comment-attachments')
        .remove()
      $(`#comment_${commentId}`).find('.comment-content').find('hr').remove()
    }
  } else {
    window.open(
      $(element).attr('href'),
      'img_window',
      'width=600,height=600,scrollbars=no'
    )
  }
}

const getFormCommentAttachments = function (containerId) {
  const attachments: any[] = []
  const attachmentsContainer = $(containerId).find('ul.attachments')
  // eslint-disable-next-line consistent-return
  attachmentsContainer.find('li').each((idx: any, li: any): any => {
    const link = $(li).find('a')
    const img = $(li).find('img')
    const attachment: any = { url: link.attr('href'), kind: 'image' }
    if (img.data().attachmentId) {
      attachment.id = img.data().attachmentId
    }
    if ($(containerId).data().commentId) {
      attachment.comment_id = $(containerId).data().commentId
    }
    if (
      attachment.url &&
      attachment.url !== '' &&
      attachment.url !== '#' &&
      !(window as any)._.some(attachments, { url: attachment.url })
    ) {
      if (!attachment.id) {
        return attachments.push(attachment)
      }
    }
  })
  return attachments
}

// Rendering

const renderCommentItem = function (comment) {
  const commentElement = generateCommentHTML(comment)
  $('#comments_wrapper').append(commentElement)
}

const generateCommentHTML = function (comment) {
  const actions = commentActions(comment)
  // eslint-disable-next-line max-len
  // comment.css_classes += " comments__block--current-user" if currentUserData.id && currentUserData.id == comment.user_id
  // eslint-disable-next-line no-param-reassign
  comment.css_classes = commentCssClasses(comment, false)
  let instructorLabel = ''
  if (
    ['owner', 'author'].indexOf(comment.creator_role) > -1 &&
    comment.workflow_state !== 'removed'
  ) {
    instructorLabel = `\
<label class="comments__block-box__meta-tag comments__block-box__meta-tag-instructor label label-default">
  ${localizedStr().instructor}
</label>\
`
  }
  let attachmentsSection = ''
  if (commentSettings.attachmentsEnabled && comment.attachments.length > 0) {
    attachmentsSection = `\
<hr>
<ul class='comment-attachments' id='comment_attachments'>${commentAttachmentElements(
      comment
    )}</ul>\
`
  }
  if (comment.workflow_state === 'removed') {
    return `\
<div class="${comment.css_classes}" id="comment_${comment.id}">
  <div class="commenter-profile">
    <img class="gravatar" src="${base64Icons.gravatarUrl}">
  </div>
  <div class="comments__block-box">
    <div class="comments__block-box__body">
      ${localizedStr().has_been_removed}
    </div>
  </div>
</div>\
`
  }

  const commentBodyWithUrls = makeUrlLinks(comment.body)

  return `\
<div class="${comment.css_classes}" id="comment_${comment.id}">
  <div class="commenter-profile">
    <img class="gravatar" src="${comment.gravatar_url}" alt="${
    comment.creator_name
  }">
    ${instructorLabel}
  </div>
  <div class="comments__block-box">
    <div class="comment-arrow-border">
      <div class="comment-arrow"></div>
    </div>
    <div class="comments__block-box__meta">
      <div class="comments__block-box__meta-name">${comment.creator_name}</div>
      <label class="comments__block-box__meta-tag comments__block-box__meta-tag-review label label-warning">
        ${localizedStr().awaiting_review}
      </label>
      <div class="comments__block-box__meta-time">
        ${(window as any).moment(comment.created_at).fromNow()}
      </div>
      <div class="comments__block-box__meta-actions">
        ${actions.reply}${actions.edit}${actions.approve}${actions.del}${
    actions.permalink
  }
      </div>
    </div>
    <div class="comment-content">
      <div data-original-comment-body="${escape(
        comment.body
      )}" class="comments__block-box__body">${$.trim(commentBodyWithUrls)}</div>
      ${attachmentsSection}
    </div>
    ${actions.edit_form}
  </div>
</div>
${actions.reply_form}\
`
}

const commentAttachmentsEditor = function (comment) {
  let element = ''
  // Base64 encoded version of assets/images/icon-image-add.svg
  // coffeelint: disable=max_line_length
  const base64EncodedIconSrc =
    // eslint-disable-next-line max-len
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgc3Ryb2tlPSIjOTlBMEI4IiBzdHJva2Utd2lkdGg9IjEuOCIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik0yMy44IDE0Ljk0djcuODQxYTIuMzk1IDIuMzk1IDAgMDEtMi4zOTcgMi4zOTlIMy4zOTdBMi4zOTQgMi4zOTQgMCAwMTEgMjIuNzg0VjguMTk2QTIuNDAyIDIuNDAyIDAgMDEzLjM5MyA1LjhoMTEuOTczIi8+PHBhdGggZD0iTTEuOTEyIDE3LjYybDUuNTYtNS4xOTUgNi4wODEgNS45MzUgMy4xNzEtMy4wNDIgNS41NiA1LjU4NU0yMy44IDEuNzM1djguMjlNMjcuOTQ1IDUuODhoLTguMjkiLz48L2c+PC9zdmc+Cg=='
  if ($('#comments_settings').data().attachmentsEnabled) {
    element = `\
<div class="attachments-editor" id="attachments_editor">
  <a href="#" class='add-attachment' id="add_attachment" data-comment-handler="add_image">
    <img src="${base64EncodedIconSrc}" alt="${localizedStr().add_image}">
  </a>
  <ul class="attachments" data-editable-attachments="true">${commentAttachmentElements(
    comment
  )}</ul>
</div>\
`
  }

  return element
}

const commentAttachmentElements = function (comment) {
  let elements = ''
  if (comment.attachments.length > 0) {
    comment.attachments.forEach((attachment) => {
      const sanitizedURL =
        typeof (window as any).DOMPurify !== 'undefined' &&
        (window as any).DOMPurify !== null
          ? (window as any).DOMPurify.sanitize(attachment.url)
          : undefined
      const sanitizedImageURL =
        typeof (window as any).DOMPurify !== 'undefined' &&
        (window as any).DOMPurify !== null
          ? (window as any).DOMPurify.sanitize(attachment.thumbnail_url)
          : undefined
      const element = `\
<li class="editable">
  <a href="${sanitizedURL}" data-toggle="lightbox" data-title="${attachment.name}" data-footer="${attachment.name}"
   data-comment-handler="remove_image">
    <img src='${sanitizedImageURL}' class="comment-image responsive" data-attachment-id='${attachment.id}'
     data-comment-id='${comment.id}'>
  </a>
</li>\
`
      // eslint-disable-next-line no-return-assign
      return (elements += element)
    })
  }
  return elements
}

const commentActions = function (comment) {
  const actions = {
    permalink: '',
    edit: '',
    edit_form: '',
    approve: '',
    reply: '',
    reply_form: '',
    del: '',
  }
  actions.permalink = `\
<a href="${
    comment.permalink_url
  }" target="_blank" rel="noopener" class="comments__block-box__meta-actions-permalink"
 id="permalink_${comment.id}">
  <i class="fa fa-link" aria-hidden="true"></i>
  <div class="comment-action-tooltip">
    ${localizedStr().permalink}
  </div>
</a>\
`
  if (currentUserData.id && currentUserData.id === comment.user_id) {
    actions.edit = `\
<a href="javascript:void(0)" class="comments__block-box__meta-actions-edit" id="edit_btn"
 data-comment-id="${comment.id}" data-comment-handler="edit_comment">
  <i class="fa fa-pencil" aria-hidden="true"></i>
  <div class="comment-action-tooltip">
     ${localizedStr().edit}
  </div>
</a>\
`
    actions.edit_form = `\
<form class="edit-comment-form hidden" data-comment-id="${
      comment.id
    }" id="comment_${comment.id}_edit_form"
 data-comment-handler="update_comment">
    <div class="alert alert-danger text-error hidden" id="text_error_alert">
      ${localizedStr().add_text_error}
    </div>
    <div class="comment-editor">
      <textarea name="body" id="comment_body_edit"></textarea>
      ${commentAttachmentsEditor(comment)}
    </div>
    <input class='btn btn-primary pull-right btn-md' id="comment_edit_submit" type="submit"
     value="${localizedStr().save_comment}">
</form>\
`
  }
  if (
    schoolData.schoolPreferences.comments_threaded === 'true' &&
    currentUserData.id
  ) {
    const replyObject: any = {
      attachments: [],
      user_id: currentUserData.id,
      creator_name: currentUserData.name,
      creator_role: currentUserData.role,
      // Base64 encoded of blank_gravatar.png - hack to be able to separate assets in the build
      gravatar_url: base64Icons.gravatarUrl,
      commentable_id: comment.commentable_id,
      commentable_type: comment.commentable_type,
      parent_id: comment.id,
      depth: comment.depth,
    }
    replyObject.depth += 1
    if (currentUserData.gravatar_url) {
      replyObject.gravatar_url = currentUserData.gravatar_url
    }
    replyObject.css_classes = commentCssClasses(replyObject, true)

    let instructorLabel = ''
    if (
      replyObject.creator_role === 'owner' ||
      replyObject.creator_role === 'author'
    ) {
      instructorLabel = `\
<label class="comments__block-box__meta-tag comments__block-box__meta-tag-instructor label label-default">
  ${localizedStr().instructor}
</label>\
`
    }
    if (comment.workflow_state === 'approved') {
      actions.reply = `\
<a href="javascript:void(0)" class="comments__block-box__meta-actions-reply" id="reply_btn" data-comment-id="${
        comment.id
      }"
 data-comment-handler="add_reply">
  <i class="fa fa-reply" aria-hidden="true"></i>
  <div class="comment-action-tooltip">
     ${localizedStr().reply}
  </div>
</a>\
`
    }
    actions.reply_form = `\
<div class="${replyObject.css_classes} hidden"
 id="comment_${comment.id}_reply_container">
  <div class="commenter-profile">
    <img class="gravatar" src="${replyObject.gravatar_url}" alt="${
      replyObject.creator_name
    }">
    ${instructorLabel}
  </div>
  <div class="comments__block-box new-comment-form">
    <div class="comment-arrow-border">
      <div class="comment-arrow"></div>
    </div>
    <div class="comments__block-box__meta">
      <div class="comments__block-box__meta-name">${
        replyObject.creator_name
      }</div>
      <div class="comments__block-box__meta-now">${localizedStr().now}</div>
    </div>
    <form action="/api/v1/comments" class="new-comment-reply-form" id="comment_${
      comment.id
    }_reply_form"
     data-parent-id="${comment.id}" data-comment-handler="create_reply">
        <div class="alert alert-success comments__block-box__alert--posted">
          ${localizedStr().post_success}
        </div>
        <div class="alert alert-warning comments__block-box__alert--review">
          <i class='fa fa-check' aria-hidden="true"></i> ${
            localizedStr().post_moderation
          }
        </div>
        <div class="alert alert-danger comments__block-box__alert--failed">
          ${localizedStr().post_fail}
        </div>
        <div class="alert alert-danger text-error" id="text_error_alert">
          ${localizedStr().add_text_error}
        </div>
        <input type="hidden" name="comments[commentable_id]" value="${
          replyObject.commentable_id
        }">
        <input type="hidden" name="comments[commentable_type]" value="${
          replyObject.commentable_type
        }">
        <input type="hidden" name="comments[parent_id]" value="${
          replyObject.parent_id
        }">
        <div class="comment-editor">
          <textarea placeholder="${
            localizedStr().post_placeholder
          }" name="comments[body]" id="comment_body"></textarea>
          ${commentAttachmentsEditor(replyObject)}
        </div>
        <input class='btn btn-primary pull-right btn-md' type="submit"
         value="${localizedStr().post_comment}">
    </form>
  </div>
</div>\
`
  }
  if (
    currentUserData.id &&
    currentUserData.moderator &&
    comment.workflow_state !== 'approved'
  ) {
    actions.approve = `\
<a href="javascript:void(0)" class="comments__block-box__meta-actions-approve" id="approve_btn"
 data-comment-id="${comment.id}" data-comment-handler="approve_comment">
  <i class="fa fa-check" aria-hidden="true"></i>
  <div class="comment-action-tooltip">
     ${localizedStr().approve}
  </div>
</a>\
`
  }
  if (
    currentUserData.id &&
    (currentUserData.id === comment.user_id || currentUserData.moderator)
  ) {
    actions.del = `\
<a href="javascript:void(0)" class="comments__block-box__meta-actions-delete" id="delete_btn"
 data-comment-id="${comment.id}" data-comment-handler="delete_comment">
  <i class="fa fa-times" aria-hidden="true"></i>
  <div class="comment-action-tooltip">
     ${localizedStr().delete}
  </div>
</a>\
`
  }
  return actions
}

const resetCommentForm = function (formId) {
  $(formId).find('.alert').hide()
  const form = $('.comment-editor')
  form.find('textarea').val('')
  form.find('.attachments').html('')
  $('input[type=submit]').prop('disabled', false)
}

const renderUpdatedCommentSuccess = function (response, commentId) {
  const comment = $(`#comment_${commentId}`)
  comment
    .find('.comments__block-box__body')[0]
    .setAttribute('data-original-comment-body', escape(response.body))
  comment.find('.comments__block-box__body')[0].innerHTML = makeUrlLinks(
    response.body
  )
  if (
    !comment.find('.comment-attachments')[0] ||
    (comment.find('.comment-attachments')[0] as any).length === 0
  ) {
    comment.find('.comment-content').append('<hr>')
    comment
      .find('.comment-content')
      .append("<ul class='comment-attachments' id='comment_attachments'></ul>")
  }
  comment
    .find('.comment-attachments')
    .append(commentAttachmentElements(response))
  comment.find('.comment-content').first().removeClass('hidden')
}

const renderNewCommentSuccess = function (response, originalId) {
  const commentsWrapper = $('#comments_wrapper')
  const responseCommentElement = generateCommentHTML(response)
  if (originalId) {
    $(`#comment_${originalId}_reply_container`).after(responseCommentElement)
    $(`#comment_${originalId}_reply_container`).addClass('hidden')
  } else {
    commentsWrapper
      .find('.comments__block')
      .first()
      .before(responseCommentElement)
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars

const renderEditCommentForm = function (commentId) {
  const comment = $(`#comment_${commentId}`)
  const commentBody = comment.find('.comments__block-box__body').first()
  const commentEditForm = $(`#comment_${commentId}_edit_form`)
  ;(commentEditForm.find('textarea')[0] as any).value = $.trim(
    unescape($(commentBody).attr('data-original-comment-body'))
  )
  comment.find('.comment-content').first().addClass('hidden')
  commentEditForm.removeClass('hidden')
}

// Animations

const deleteAnimation = function (commentId) {
  // if comment is an ancestor (has child comments)
  if (commentData.ancestorIds.indexOf(commentId) > -1) {
    // update UI to show comment has been removed
    $(`#comment_${commentId}`)
      .find('.comments__block-box__body')
      .html(`${localizedStr().has_been_removed}`)

    // remove comment actions (edit, delete, reply, etc.)
    $(`#comment_${commentId}`).find('.comments__block-box__meta').empty()
  } else {
    $(`#comment_${commentId}`).animate(
      { height: 0, opacity: 0 },
      'fast',
      function () {
        return $(this).hide()
      }
    )
  }
}

const approveAnimation = function (commentId) {
  const commentBody = $(`#comment_${commentId}`)
  commentBody.removeClass('comments__block--awaiting_review')
  commentBody.addClass('comments__block--approved')
  const approveAction = commentBody.find(
    '.comments__block-box__meta-actions-approve'
  )
  const replyAction = $(`\
    <a href="javascript:void(0)" class="comments__block-box__meta-actions-reply" id="reply_btn" data-comment-id="${commentId}"
     data-comment-handler="add_reply">
      <i class="fa fa-reply" aria-hidden="true"></i>
      <div class="comment-action-tooltip">
         ${localizedStr().reply}
      </div>
    </a>\
  `).hide()
  replyAction.insertBefore(approveAction)
  approveAction.hide()
  replyAction.show()
}

// Helpers
const commentCssClasses = function (comment, is_reply) {
  let classes = 'comments__block'
  classes += ` comments__block--${comment.creator_role}`
  if (
    schoolData.schoolPreferences &&
    schoolData.schoolPreferences.comments_threaded &&
    // tslint:disable-next-line:no-magic-numbers
    comment.depth > 2
  ) {
    classes += ' comments__block--indent-level-2'
  } else if (
    schoolData.schoolPreferences &&
    schoolData.schoolPreferences.comments_threaded &&
    // tslint:disable-next-line:no-magic-numbers
    comment.depth <= 2
  ) {
    classes += ` comments__block--indent-level-${comment.depth}`
  } else {
    classes += ' comments__block--indent-level-0'
  }
  if (is_reply) {
    classes += ' comments__block--current-user'
    classes += ' comments__block--new'
  } else {
    classes += ` comments__block--${comment.workflow_state}`
  }
  return classes
}

const imageElement = function (filepickerResponse) {
  const element = `\
<li class="editable">
  <a class="remove-img" href="${filepickerResponse.url}" data-toggle="lightbox" data-comment-handler="remove_image">
    <img src="${filepickerResponse.url}" class="comment-image responsive">
  </a>
</li>\
`
  return element
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars

const localizedStr = () => $('#comments_settings').data().i18n

const updateCommentCount = function (change) {
  commentData.loadedCount += change
  commentData.totalCount += change
  $('#comments_total').text(commentData.totalCount)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars

// eslint-disable-next-line @typescript-eslint/no-unused-vars

$(document).on('ready page:load', () => {
  ready()
})

function commentsGuard(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined
}
