const handleVatUpdatesForUpsellButton = () => applyVatToCheckoutButton()

const applyVatToCheckoutButton = () => {
  if (vatTaxableCountry() && confirmButtonPresent()) {
    const productIds = $('.btn-instant-checkout').map(
      (index, btn: any) => btn.value
    )
    return $.ajax({
      url: `${vatUpsellCheckerPath()}?${$.param({
        product_id: productIds.toArray(),
      } as any)}`,
      method: 'GET',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    }).then(includeVatInPrices)
  }

  return undefined
}

let includeVatInPrices = (data) =>
  Array.from(data.vat_adjusted_products).map((vatProduct: any) =>
    $(
      `.price#instant-checkout-upsell-price-product-${vatProduct.product_id}`
    ).each((index, btn) => {
      btn.textContent = vatProduct.formatted_final_price
      return btn.removeAttribute('hidden')
    })
  )

let vatUpsellCheckerPath = () => '/vat_rate_for_upsell_products'

let vatTaxableCountry = () => $('meta[id=fedora-data]').attr('data-vat-country')

let confirmButtonPresent = () => $('.btn-instant-checkout').length > 0

$(document).ready(handleVatUpdatesForUpsellButton)
